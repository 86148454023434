import { query, createAsync } from "@solidjs/router";
import { _retrieveCVC } from "../roma-api/cvc";

export const getCVC = query(
  async (cvc_id: string) => await _retrieveCVC(cvc_id),
  "cvc"
);

export const useCVC = (
  cvcID: () => string,
  options: { deferStream: boolean } = { deferStream: false }
) => createAsync(() => getCVC(cvcID()), options);
