import { CVCCountResponse } from "./types";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

export const _retrieveCVCCounts = async () => {

  // await simulateApiError({path: "/cvc"})

  const data = await fetchAPI2<CVCCountResponse>("/cvc", { unauthenticated: true });

  return data;
};
