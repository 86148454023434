import { action, json } from "@solidjs/router";
import { _submitCVC } from "../roma-api/cvc";
import { getCVCCounts } from "./useCVCCounts";
import { type CVCPayload } from "../roma-api/cvc/types";

export const sendCVCAction = action(async (payload: CVCPayload) => {
  const response = await _submitCVC(payload);

  return json(response, { revalidate: getCVCCounts.key });
});
